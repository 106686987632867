
import { computed, defineComponent } from "vue";
import store from "@/store";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import PolicyForm from "@/views/policy/PolicyForm.vue";
import { IPolicy } from "@/core/entity/IPolicy";
import { onUpdated } from "@vue/runtime-core";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Update",
  components: {PolicyForm},
  setup() {
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Policy', [
        {link: true, router: '/policies', text: 'Search Policy'},
        {link: true, router: '/policies/' + policy.value?.id, text: 'View Policy'},
        {link: false, router: '', text: 'Update Policy'}
      ])
    })
    return {
      ...LoadEntity(Actions.LOAD_POLICY),
      policy,
    }
  }
})
